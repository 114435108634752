import './contact-info.module.scss';
import contactBg from '../../assets/images/contact-bg.jpg';
import locationIcon from '../../assets/images/location-icon.svg';
import phoneIcon from '../../assets/images/phone-icon.svg';
import envelopeIcon from '../../assets/images/envelop-icon.svg';
import clockIcon from '../../assets/images/wall-clock-icon.svg';

/* eslint-disable-next-line */
export interface ContactInfoProps {}

export function ContactInfo(props: ContactInfoProps) {
  return (
    <section id="contact-info">
      <div className="contact-img">
        <img src={contactBg} alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contact-info">
              <h5 className="x-small-heading transf-caps mar-bottom-rem">
                CONTACT INFO
              </h5>
              <h3 className="x-large-heading no-text-shadow">Let’s Connect!</h3>
              <div className="row">
                <div className="col-lg-6">
                  <div className="col-cont-info">
                    <div className="cnt-title">
                      <i className="cnt-icon">
                        <img src={locationIcon} alt="" />
                      </i>
                      <span className="cnt-text">Headquarters</span>
                    </div>
                    <p>
                      <a
                        href="https://goo.gl/maps/m1jMGjT61JjdAVJ26"
                        className="color-light-black"
                        target="_blabk"
                      >
                        101 Eisenhower Parkway Suite 300 Roseland, NJ 07068
                      </a>{' '}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-cont-info">
                    <div className="cnt-title">
                      <i className="cnt-icon">
                        <img src={locationIcon} alt="" />
                      </i>
                      <span className="cnt-text">Call Us</span>
                    </div>
                    <p>
                      <a href="tel:800-484-0350" className="color-light-black">
                        800-484-0350
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-cont-info">
                    <div className="cnt-title">
                      <i className="cnt-icon">
                        <img src={locationIcon} alt="" />
                      </i>
                      <span className="cnt-text">E-mail</span>
                    </div>
                    <p>
                      <a
                        href="mailto:contact@dgmmanagement.com"
                        className="color-light-black"
                      >
                        contact@dgmmanagement.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="col-cont-info">
                    <div className="cnt-title">
                      <i className="cnt-icon">
                        <img src={locationIcon} alt="" />
                      </i>
                      <span className="cnt-text">Business Hours</span>
                    </div>
                    <p>Mon - Fri: 7am – 6pm</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactInfo;
