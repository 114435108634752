import './text-slider.module.scss';
import banner from '../../assets/images/banner-image.png';
import bussinesBanner from '../../assets/images/bussines-own.png';
import execBanner from '../../assets/images/exec-banner.png';
import commercialBanner from '../../assets/images/commercial-banner.png';
import medicalBanner from '../../assets/images/medical-banner.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';

/* eslint-disable-next-line */
export interface TextSliderProps {}

export function TextSlider(props: TextSliderProps) {
  return (
    <section id="text-slider">
      <Swiper
        modules={[Navigation, EffectFade, Autoplay]}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        slidesPerView={1}
        watchSlidesProgress
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
      >
        <SwiperSlide>
          <div className="item-ts">
            <div className="banner-image">
              <img src={banner} alt="Banner Background" />
            </div>
            <div className="text-slider-container">
              <div className="container">
                <div className="row justify-content-end">
                  <div className="col-xl-6 col-lg-5 col-md-12">
                    <div className="text-slider">
                      <div className="ts-slide">
                        <div className="ts-content">
                          <h3 className="medium-heading medium-wgt">
                            Loved Ones with Disabilities
                          </h3>
                          <p className="color-light-black norma-size-font">
                            From personal experience, we know what it is like
                            when you have a family member living with a
                            disability. We have experience in managing trusts
                            for loved ones and in navigating many of the state
                            demands in doing so. . We can create a plan designed
                            to provide for your loved ones today and in the
                            future. This plan would also feature strategies to
                            help ensure your loved one receives support from all
                            potential resources..{' '}
                          </p>
                          <a
                            href="/our-specialities#disablities"
                            className="btn-theme btn-md"
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="item-ts">
            <div className="banner-image bannerCaptionSp">
              <img src={bussinesBanner} alt="Banner Background" />
            </div>
            <div className="text-slider-container">
              <div className="container">
                <div className="row justify-content-end">
                  <div className="col-xl-6 col-lg-5 col-md-6">
                    <div className="text-slider">
                      <div className="ts-slide">
                        <div className="ts-content">
                          <h3 className="medium-heading medium-wgt">
                            Business Owners
                          </h3>
                          <p className="color-light-black norma-size-font">
                            As an entrepreneur, we know you have to have a
                            long-term vision and wear a lot of hats. We work
                            with business owners like yourself to help
                            crystallize that long-term vision of yours into an
                            actionable series of processes to bring you success.
                            We can help through proper valuation of your
                            company, increasing cash flow and developing a
                            succession strategy. DGM will help you create a path
                            for success today that will pay dividends in the
                            future..
                          </p>
                          <a
                            href="/our-specialities#business"
                            className="btn-theme btn-md"
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="item-ts">
            <div className="banner-image">
              <img src={execBanner} alt="Banner Background" />
            </div>
            <div className="text-slider-container">
              <div className="container">
                <div className="row justify-content-end">
                  <div className="col-xl-6 col-lg-5 col-md-12">
                    <div className="text-slider">
                      <div className="ts-slide">
                        <div className="ts-content">
                          <h3 className="medium-heading medium-wgt">
                            Executives
                          </h3>
                          <p className="color-light-black norma-size-font">
                            As a business leader, you're likely a person who
                            sees the big picture. DGM will offer specific
                            services for you and your skillset, including
                            negotiating benefits packages, establishing tax
                            efficient estate planning and trust plans, tax
                            strategies for charitable giving, multi state tax
                            filing and wealth transfer, and a wealth plan that
                            takes into consideration the uniqueness of your
                            compensation package, all to help you accumulate,
                            preserve and pass along your wealth in a way that
                            creates the legacy you desire.
                          </p>
                          <a
                            href="/our-specialities#executives"
                            className="btn-theme btn-md"
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="item-ts">
            <div className="banner-image">
              <img src={medicalBanner} alt="Banner Background" />
            </div>
            <div className="text-slider-container">
              <div className="container">
                <div className="row justify-content-end">
                  <div className="col-xl-6 col-lg-5 col-md-12">
                    <div className="text-slider">
                      <div className="ts-slide">
                        <div className="ts-content">
                          <h3 className="medium-heading medium-wgt">
                            Medical Professionals
                          </h3>
                          <p className="color-light-black norma-size-font">
                            In our experiences, no professional is busier than a
                            doctor; often, their commitment to take care of
                            others leaves little time to take care of their own
                            finances and retirement plans. We offer strategies
                            for physicians and other medical professionals like
                            yourself that includes wealth management, charitable
                            giving, asset protection and estate planning to help
                            you provide the peace of mind that you provide your
                            patients with their health and well-being.
                          </p>
                          <a
                            href="/our-specialities#medical"
                            className="btn-theme btn-md"
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="item-ts">
            <div className="banner-image">
              <img src={commercialBanner} alt="Banner Background" />
            </div>
            <div className="text-slider-container">
              <div className="container">
                <div className="row justify-content-end">
                  <div className="col-xl-6 col-lg-5 col-md-12">
                    <div className="text-slider">
                      <div className="ts-slide">
                        <div className="ts-content">
                          <h3 className="medium-heading medium-wgt">
                            Commercial Real Estate
                          </h3>
                          <p className="color-light-black norma-size-font">
                            If you are a commercial real estate investor, you
                            often have a unique set of challenges. Most often,
                            that may be an overweighting in the asset at the
                            expense of diversification in other areas.
                            Transitioning these assets to a corporate structure
                            can be paramount to ensuring that the next
                            generation is taken care of, as governance is an
                            often overlooked way to protect family wealth and
                            preserve your legacy for generations to come.
                          </p>
                          <a
                            href="/our-specialities#commercial"
                            className="btn-theme btn-md"
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="ts-navigation row justify-content-end">
        <div className="col-xl-6 col-lg-5 col-md-12 d-flex">
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </section>
  );
}

export default TextSlider;
