import './card.module.scss';
import React, { Component } from 'react';

/* eslint-disable-next-line */
export interface CardProps {
  heading?: string;
  content?: string;
  link?: string;
  linktext?: string;
}

class Card extends Component<CardProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { link, heading, linktext, content } = this.props;
    return (
      <div className="service-card">
        <div className="service-card-body text-center">
          {heading}
          {content}
          {linktext && (
            <a href={link} className="btn-theme-outline btn-md">
              {' '}
              {linktext}{' '}
            </a>
          )}
        </div>
      </div>
    );
  }
}

export default Card;
