import './index.module.scss';
import Helmet from 'react-helmet';
import Layout from '../layout';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import HeroBanner from '../components/hero-banner/hero-banner';
import Services from '../components/services/services';
import TextSlider from '../components/text-slider/text-slider';
import NewsletterProps from '../components/newsletter/newsletter';
import ContactInfoProps from '../components/contact-info/contact-info';
import plan from '../assets/images/plan-your-journey.jpg';

const { marketingWebUrl } = process.env;
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title={`DGM Asset Management`}>
        {/* General tags */}
        <meta name="description" content="" />
        <meta name="image" content="" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`DGM Asset Management`} />
        <meta
          property="og:description"
          content="DGM was formed in 1999 by Wall Street professionals with the express interest of bringing institutional products, research, advice and money management to individuals who were not institutional investors by nature."
        />
        <meta property="og:url" content={marketingWebUrl} />
        <meta property="og:site_name" content={'DGM Asset Management'} />
        <meta property="og:image" content={plan} />
      </Helmet>
      <Header />
      <HeroBanner />
      <Services />
      <TextSlider />
      <NewsletterProps />
      <ContactInfoProps />
      <Footer />
    </Layout>
  );
}

export default Index;
