import './services.module.scss';
import vasIcon from '../../assets/images/vas.svg';
import dollarIcon from '../../assets/images/dollar-coin.svg';
import groupIcon from '../../assets/images/group.svg';
import shareIcon from '../../assets/images/share-money.svg';
import TreeCol from '../tree-col/tree-col';
import { spawn } from 'node:child_process';
import Card from '../card/card';

/* eslint-disable-next-line */
export interface ServicesProps {}

export function Services(props: ServicesProps) {
  return (
    <section id="services">
      <h2 className="text-center large-heading color-light-black">
        Explore our Services
      </h2>
      <div className="container">
        <div className="services-container">
          <div className="d-flex justify-content-between">
            <Card
              heading={
                <h3 className="large-heading color-white">
                  {' '}
                  Services For Individuals{' '}
                </h3>
              }
              content={
                <p>
                  Our team will design a comprehensive wealth management
                  strategy that coordinates investments, taxes, estate planning
                  and insurance solutions that reflects your views of money,
                  life and generational wealth.
                </p>
              }
              linktext="Explore Our Services"
              link={'/our-services/services-for-Individuals'}
            />
            <Card
              heading={
                <h3 className="large-heading color-white">
                  {' '}
                  Services For Businesses{' '}
                </h3>
              }
              content={
                <p>
                  You focus on your business - we'll help with everything else.
                  We can design tax strategies, retirement planning solutions
                  and specialized business services as part of an integrated
                  business financial strategy.
                </p>
              }
              linktext="Explore Our Services"
              link={'/our-services/services-for-businesses'}
            />
          </div>
        </div>

        <div className="serv-tree">
          <TreeCol
            title={
              <h2 className="large-heading color-black">
                Wealth Management for <br /> your Life Path
              </h2>
            }
          />
          <TreeCol
            title={<h2 className="medium-heading color-theme">First steps</h2>}
            iconImage={vasIcon}
            iconAlt={'icon vas'}
            content={
              <p className="norma-size-font">
                <strong>Plan for Your Future.</strong>
                Investing wisely today will have a profound impact on one’s
                future. Whether you’re starting in business or starting a
                business, raising a family or or retiring, your assets need to
                grow along with you at an appropriate rate.
              </p>
            }
          />
          <TreeCol
            title={<h2 className="medium-heading color-theme">Protect</h2>}
            iconImage={dollarIcon}
            iconAlt={'icon vas'}
            content={
              <p className="norma-size-font">
                <strong>Protect Your Nest Egg.</strong>
                No one wants to feel like they may outlive their money. DGM can
                help provide you the plan to ensure that you know your loved
                ones will be taken care of in the future. We will work with you
                on a path designed to both protect your assets from life’s
                surprises and to grow them as well.
              </p>
            }
          />
          <TreeCol
            title={<h2 className="medium-heading color-theme">Pivot</h2>}
            iconImage={shareIcon}
            iconAlt={'icon vas'}
            content={
              <p className="norma-size-font">
                <strong>Your Path May Change.</strong>
                Not everything in business or life goes according to plan.
                Business and marriages do fail, and health issues can present
                their own set of challenges, both from a monetary standpoint as
                well as an emotional one. Going through these challenges can be
                stressful and it is helpful to have a team to advise and support
                you. We will be here to listen, offer encouragement and will
                work to ease your financial stress by creating strategies to
                help ensure you and your loved ones are in good hands.
              </p>
            }
          />
          <TreeCol
            title={<h2 className="medium-heading color-theme">Charity</h2>}
            iconImage={groupIcon}
            iconAlt={'icon vas'}
            content={
              <p className="norma-size-font">
                <strong>Creating a Legacy</strong>
                There are few greater joys than knowing your assets can make a
                difference in this world for someone else. We can help ensure
                that as you share your wealth with loved ones and charities near
                and dear to your heart, your legacy will be one of generosity
                that will not just help people today, but may also help and
                inspire others generations for generations to come. With our
                trusted contacts, we can ensure that proper estate planning and
                trust services are an integral part of your asset management
                strategy.
              </p>
            }
          />
        </div>
      </div>
    </section>
  );
}

export default Services;
