import './hero-banner.module.scss';
import plan from '../../assets/images/plan-your-journey.jpg';
import reach from '../../assets/images/reach-for-the-stars.jpg';
import safeguard from '../../assets/images/safeguard-your-assets.jpg';
import success from '../../assets/images/plan-for-success.jpg';
import improve from '../../assets/images/improve-lives.jpg';
import enjoy from '../../assets/images/enjoy-your-loved-ones.jpg';
import legacy from '../../assets/images/create-your-legacy.jpg';
import generational from '../../assets/images/build-generational-wealth.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

/* eslint-disable-next-line */
export interface HeroBannerProps {}

export function HeroBanner(props: HeroBannerProps) {
  return (
    <section id="hero">
      <div className="hero-banner">
        <Swiper
          modules={[Navigation, EffectFade, Autoplay]}
          navigation={true}
          slidesPerView={1}
          watchSlidesProgress
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
        >
          <SwiperSlide>
            <div className="hr-slide">
              <div className="slide-img">
                <img src={plan} alt="Plan Your Journey" />
              </div>
              <div className="caption">
                <div className="container-small">
                  <h2 className="x-large-heading color-white">
                    Plan Your Journey
                  </h2>
                  <a
                    href={'/contact-us/request-form'}
                    className="btn-theme btn-md"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hr-slide">
              <div className="slide-img">
                <img src={generational} alt="slide" />
              </div>
              <div className="caption">
                <div className="container-small">
                  <h2 className="x-large-heading color-white">
                    Build Generational Wealth
                  </h2>
                  <a
                    href={'/contact-us/request-form'}
                    className="btn-theme btn-md"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hr-slide">
              <div className="slide-img">
                <img src={safeguard} alt="slide" />
              </div>
              <div className="caption">
                <div className="container-small">
                  <h2 className="x-large-heading color-white">
                    Safeguard Your Assets
                  </h2>
                  <a
                    href={'/contact-us/request-form'}
                    className="btn-theme btn-md"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hr-slide">
              <div className="slide-img">
                <img src={reach} alt="slide" />
              </div>
              <div className="caption">
                <div className="container-small">
                  <h2 className="x-large-heading color-white">
                    Reach For the Stars
                  </h2>
                  <a
                    href={'/contact-us/request-form'}
                    className="btn-theme btn-md"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hr-slide">
              <div className="slide-img">
                <img src={enjoy} alt="slide" />
              </div>
              <div className="caption">
                <div className="container-small">
                  <h2 className="x-large-heading color-white">
                    Enjoy Your Loved Ones
                  </h2>
                  <a
                    href={'/contact-us/request-form'}
                    className="btn-theme btn-md"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hr-slide">
              <div className="slide-img">
                <img src={legacy} alt="slide" />
              </div>
              <div className="caption">
                <div className="container-small">
                  <h2 className="x-large-heading color-white">
                    Create Your Legacy
                  </h2>
                  <a
                    href={'/contact-us/request-form'}
                    className="btn-theme btn-md"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hr-slide">
              <div className="slide-img">
                <img src={success} alt="slide" />
              </div>
              <div className="caption">
                <div className="container-small">
                  <h2 className="x-large-heading color-white">
                    Plan for Success
                  </h2>
                  <a
                    href={'/contact-us/request-form'}
                    className="btn-theme btn-md"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hr-slide">
              <div className="slide-img">
                <img src={improve} alt="slide" />
              </div>
              <div className="caption">
                <div className="container-small">
                  <h2 className="x-large-heading color-white">Improve Lives</h2>
                  <a
                    href={'/contact-us/request-form'}
                    className="btn-theme btn-md"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}

export default HeroBanner;
